import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { API_BASE_URL_V } from "src/app/data-wp.service";
import { AuthService } from "src/app/services/auth.service";
import { SocioData } from "src/app/shared/socio-data.interface";

@Component({
  selector: "app-pedido-de-factura",
  templateUrl: "./pedido-de-factura.component.html",
  styleUrls: ["./pedido-de-factura.component.css"],
})
export class PedidoDeFacturaComponent implements OnInit {
  constructor(
    private authSrv: AuthService,
    private http: HttpClient,
    private modalService: NgbModal,
    private spinner: NgxUiLoaderService
  ) { }
  @ViewChild("modalRespuesta", { static: false }) modalRespuesta: ElementRef;
  datosSocio: SocioData;
  socio_nombre: string;
  socio_apellido: string;
  socio_numero: string;
  socio_dominio: string;
  modalMessage: string;
  facturas;
  ngOnInit() {
    this.datosSocio = JSON.parse(sessionStorage.getItem("data"));
    console.log('Datos del socio:', this.datosSocio);
    this.spinner.start();
    this.http
      .get<any>(
        API_BASE_URL_V +
        `/facturas?socio_numero=${this.datosSocio.socio_numero}&socio_dominio=${this.datosSocio.socio_dominio}`
      )
      .subscribe((res) => {
        if (res.success) {
          this.spinner.stop();
          if (res.data.facturas) {
            this.facturas = res.data.facturas;
            console.log('Facturas recibidas:', this.facturas); // Log de las facturas recibidas
          } else {
            console.log('No hay facturas disponibles.'); // Log si no hay facturas
          }
        } else {
          this.spinner.stop();
          this.modalMessage = res.message;
          this.openModal(this.modalRespuesta);
        }
      });
  }

  logout() {
    this.authSrv.logout();
  }

  openModal(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-socios-form",
    });
  }

  getMonth(fecha) {
    let formatofecha = fecha.split("/");
    let fechafinal =
      formatofecha[1] + "/" + formatofecha[0] + "/" + formatofecha[2];
    return new Intl.DateTimeFormat("es-ES", { month: "long" }).format(
      new Date(fechafinal)
    );
  }
  getYear(fecha) {
    let formatofecha = fecha.split("/");

    return formatofecha[2];
  }
}
