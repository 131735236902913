import { Component, OnInit, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-ecommerce',
  templateUrl: './ecommerce.component.html',
  styleUrls: ['./ecommerce.component.css']
})
export class EcommerceComponent implements OnInit, OnDestroy, PipeTransform {

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer, private router: Router) { }

  plan;
  url;
  private sub;
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit() {

    this.sub = this.route.params.subscribe(params => {
      this.plan = params['plan'];
      this.url = `https://jvulcanodev.online/gestion/ecommerce/${this.plan}/`;
      this.transform(this.url)
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}

