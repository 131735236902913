import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { API_BASE_URL_JSON } from "src/app/data-wp.service";
import { emailData } from "src/app/shared/emailData";
import { EmpresaData } from "src/app/shared/empresa-data.interface";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: "app-pedido-de-contrato",
  templateUrl: "./pedido-de-contrato.component.html",
  styleUrls: ["../../empresa-gestion-online.component.css"],
})
export class PedidoDeContratoComponent implements OnInit {
  isLoading: boolean = false;
  constructor(
    public formBuilder: FormBuilder,
    private http: HttpClient,
    private modalService: NgbModal,
    private spinner: NgxUiLoaderService
  ) { }
  get domicilioLegal() {
    return this.contactForm.get("domicilioLegal");
  }
  get firmante() {
    return this.contactForm.get("firmante");
  }
  get piso() {
    return this.contactForm.get("piso");
  }
  get areaProtegida() {
    return this.contactForm.get("areaProtegida");
  }
  get email() {
    return this.contactForm.get("email");
  }
  get localidad() {
    return this.contactForm.get("localidad");
  }
  get condicionIva() {
    return this.contactForm.get("condicionIva");
  }
  get telefono() {
    return this.contactForm.get("telefono");
  }
  get aclaraciones() {
    return this.contactForm.get("aclaraciones");
  }
  get razonSocial() {
    return this.contactForm.get("razonSocial");
  }
  get nombreFantasia() {
    return this.contactForm.get("nombreFantasia");
  }
  get cuit() {
    return this.contactForm.get("cuit");
  }
  get dniFirmante() {
    return this.contactForm.get("dniFirmante");
  }
  get cargoFirmante() {
    return this.contactForm.get("cargoFirmante");
  }
  get domicilioCobertura() {
    return this.contactForm.get("domicilioCobertura");
  }
  get telefonoCobertura() {
    return this.contactForm.get("telefonoCobertura");
  }

  form: FormGroup;
  datosEmpresa: EmpresaData;
  testDatos: EmpresaData;
  modalMessage: string;

  @Output() closeModal = new EventEmitter();
  @ViewChild("modalRespuesta", { static: false }) modalRespuesta: ElementRef;

  contactForm = this.formBuilder.group({
    domicilioLegal: ["", [Validators.required]],
    firmante: ["", [Validators.required]],
    areaProtegida: ["", [Validators.required]],
    email: [
      "",
      [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$"),
      ],
    ],
    localidad: ["", [Validators.required]],
    condicionIva: ["", [Validators.required]],
    telefono: ["", [Validators.required]],
    razonSocial: ["", [Validators.required]],
    nombreFantasia: ["", [Validators.required]],
    cuit: ["", [Validators.required, Validators.pattern("^[0-9]{11}$")]],
    dniFirmante: [
      "",
      [Validators.required, Validators.pattern("^[0-9]{7,8}$")],
    ],
    cargoFirmante: ["", [Validators.required]],
    domicilioCobertura: ["", [Validators.required]],
    telefonoCobertura: ["", [Validators.required]],
  });

  ngOnInit() {
    this.datosEmpresa = JSON.parse(sessionStorage.getItem("data"));
    //////////////////////////////////////
    /// For Test
    /////////////////////////////////////
    this.testDatos = {
      empresa_cuit: "",
      empresa_codigo: "",
      empresa_razon_social: "",
      empresa_dominio: "",
      empresa_email: "",
      empresa_factura: "",
      empresa_nombre: "",
      empresa_plan_tipo: "",
      empresa_telefono: "",
    };
  }

  submitForm() {
    this.spinner.start();
    let formData = new FormData();
    let form = this.contactForm.value;

    formData.append("empresa_codigo", this.datosEmpresa.empresa_codigo);
    formData.append("empresa_cuit", this.datosEmpresa.empresa_cuit);
    this.datosEmpresa.empresa_nombre.length > 0
      ? formData.append(
        "empresa_razon_social",
        this.datosEmpresa.empresa_nombre
      )
      : formData.append("empresa_razon_social", "NO posee razón social");
    formData.append("empresa_dominio", this.datosEmpresa.empresa_dominio);
    formData.append("empresa_email", this.datosEmpresa.empresa_email);
    formData.append("domicilioLegal", form.domicilioLegal);
    formData.append("firmante", form.firmante);
    formData.append("areaProtegida", form.areaProtegida);
    formData.append("email", form.email);
    formData.append("condicionIva", form.condicionIva);
    formData.append("localidad", form.localidad);
    formData.append("razonSocial", form.razonSocial);
    formData.append("telefono", form.telefono);
    formData.append("nombreFantasia", form.nombreFantasia);
    formData.append("cuit", form.cuit);
    formData.append("dniFirmante", form.dniFirmante);
    formData.append("cargoFirmante", form.cargoFirmante);
    formData.append("domicilioCobertura", form.domicilioCobertura);
    formData.append("telefonoCobertura", form.telefonoCobertura);

    const emailDestino = this.datosEmpresa.empresa_dominio.length > 0 && emailData[this.datosEmpresa.empresa_dominio]
      ? emailData[this.datosEmpresa.empresa_dominio]
      : "cas@vittal.com.ar";

    if (!this.isValidEmail(emailDestino)) {
      this.modalMessage = "La dirección de correo electrónico no es válida.";
      this.openModal(this.modalRespuesta);
      this.spinner.stop();
      return;
    }

    formData.append("email_destino", emailDestino);

    this.http
      .post<any>(
        API_BASE_URL_JSON + "/contact-form-7/v1/contact-forms/3820/feedback",
        formData
      )
      .subscribe(
        (res) => {
          this.spinner.stop();
          console.log(res);
          if (res.status === "mail_sent") {
            this.closeModal.emit();
            this.modalMessage = res.message;
            this.openModal(this.modalRespuesta);
          } else {
            this.modalMessage = res.message;
            this.openModal(this.modalRespuesta);
          }
        },
        (error) => {
          console.error('Error al enviar el formulario:', error);
          this.modalMessage = "Ocurrió un error al enviar el formulario. Inténtalo de nuevo más tarde.";
          this.openModal(this.modalRespuesta);
        }
      );
  }

  getErrorMessage(): string {
    let messages = [];
    const controls = this.contactForm.controls;

    for (const controlName in controls) {
      if (controls[controlName].invalid) {
        messages.push(`${controlName} es inválido.`);
      }
    }

    return messages.length > 0 ? messages.join(' ') : 'El formulario tiene errores.';
  }

  isValidEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  openModal(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-socios-form",
    });
  }
}
