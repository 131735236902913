import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { SocioData } from 'src/app/shared/socio-data.interface';

@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html',
  styleUrls: ['./perfil-usuario.component.css']
})
export class PerfilUsuarioComponent implements OnInit {

  constructor(private authSrv: AuthService) { }

  datosSocio: SocioData;
  socio_nombre: string;
  socio_apellido: string;
  socio_numero: string;


  ngOnInit() {
    this.datosSocio = JSON.parse(sessionStorage.getItem('data'));
    console.log(this.datosSocio);
    // Asegúrate de que 'socio_razon_social' esté en los datos
    this.socio_numero = this.datosSocio.socio_numero;
  }

  logout() {
    this.authSrv.logout();
  }
}
