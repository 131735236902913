import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { API_BASE_URL_JSON } from "src/app/data-wp.service";
import { emailData } from "src/app/shared/emailData";
import { EmpresaData } from "src/app/shared/empresa-data.interface";
import { NgxUiLoaderService } from "ngx-ui-loader";
@Component({
  selector: "app-constancia-atencion-empresas",
  templateUrl: "./constancia-atencion-empresas.component.html",
  styleUrls: ["../../empresa-gestion-online.component.css"],
})
export class ConstanciaAtencionEmpresasComponent implements OnInit {
  constructor(
    public formBuilder: FormBuilder,
    private http: HttpClient,
    private modalService: NgbModal,
    private datepipe: DatePipe,
    private spinner: NgxUiLoaderService
  ) { }

  form: FormGroup;
  datosEmpresa: EmpresaData;
  testDatos: EmpresaData;
  modalMessage: string;
  hoy;
  fechaHoy;
  @ViewChild("modalRespuesta", { static: false }) modalRespuesta: ElementRef;
  @Output() closeModal = new EventEmitter();

  get fecAtencion() {
    return this.contactForm.get("fecAtencion");
  }
  get telefono() {
    return this.contactForm.get("telefono");
  }

  contactForm = this.formBuilder.group({
    fecAtencion: ["", [Validators.required]],
    telefono: ["", [Validators.required]],
  });

  ngOnInit() {
    this.datosEmpresa = JSON.parse(sessionStorage.getItem("data"));
    //////////////////////////////////////
    /// For Test
    /////////////////////////////////////
    this.testDatos = {
      empresa_cuit: "",
      empresa_codigo: "",
      empresa_razon_social: "",
      empresa_dominio: "",
      empresa_email: "",
      empresa_factura: "",
      empresa_nombre: "",
      empresa_plan_tipo: "",
      empresa_telefono: "",
    };
    this.fechaHoy = Date.now();
    this.hoy = this.datepipe.transform(this.fechaHoy, "yyyy-MM-dd");
  }

  submitForm() {
    this.spinner.start();
    //this.datosEmpresa = Object.assign({}, this.testDatos);

    let formData = new FormData();
    let form = this.contactForm.value;
    formData.append("empresa_codigo", this.datosEmpresa.empresa_codigo);
    formData.append("empresa_cuit", this.datosEmpresa.empresa_cuit);
    this.datosEmpresa.empresa_nombre.length > 0
      ? formData.append(
        "empresa_razon_social",
        this.datosEmpresa.empresa_nombre
      )
      : formData.append("empresa_razon_social", "NO posee razón social");
    formData.append("empresa_dominio", this.datosEmpresa.empresa_dominio);
    formData.append("empresa_email", this.datosEmpresa.empresa_email);
    formData.append("fecha_atencion", form.fecAtencion);
    formData.append("telefono", form.telefono);

    const emailDestino = this.datosEmpresa.empresa_dominio.length > 0 && emailData[this.datosEmpresa.empresa_dominio]
      ? emailData[this.datosEmpresa.empresa_dominio]
      : "cas@vittal.com.ar";

    formData.append("email_destino", emailDestino);

    this.http
      .post<any>(
        API_BASE_URL_JSON + "/contact-form-7/v1/contact-forms/3816/feedback",
        formData
      )
      .subscribe((res) => {
        this.spinner.stop()
        if ((res.status = "mail_sent")) {
          this.closeModal.emit();
          this.modalMessage = res.message;
          this.openModal(this.modalRespuesta);
        } else {
          this.modalMessage = res.message;
          this.openModal(this.modalRespuesta);
        }
      });
  }
  openModal(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-socios-form",
    });
  }
}
