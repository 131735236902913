import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { API_BASE_URL_V } from "src/app/data-wp.service";
import { AuthService } from "src/app/services/auth.service";
import { EmpresaData } from "src/app/shared/empresa-data.interface";

@Component({
  selector: "app-empresa-facturacion",
  templateUrl: "./empresa-facturacion.component.html",
  styleUrls: ["./empresa-facturacion.component.css"],
})
export class EmpresaFacturacionComponent implements OnInit {
  constructor(
    private authSrv: AuthService,
    private http: HttpClient,
    private modalService: NgbModal,
    private spinner: NgxUiLoaderService
  ) { }

  @ViewChild("modalRespuesta", { static: false }) modalRespuesta: ElementRef;
  datosEmpresa: EmpresaData;
  modalMessage: string;
  facturas: any[];


  ngOnInit() {
    this.datosEmpresa = JSON.parse(sessionStorage.getItem("data"));
    console.log('Datos de la empresa:', this.datosEmpresa)

    this.spinner.start();
    this.http
      .get<any>(API_BASE_URL_V + "/empresa/facturas", {
        params: {
          empresa_codigo: this.datosEmpresa.empresa_codigo,
          empresa_dominio: this.datosEmpresa.empresa_dominio,
        },
      })
      .subscribe((res) => {
        this.spinner.stop();
        console.log('Respuesta de la API:', res);
        // Mover aquí para asegurarte de que se detenga en todos los casos
        if (res.success) {
          if (res.data.facturas) {
            this.facturas = res.data.facturas;
            console.log('Facturas recibidas:', this.facturas);
            this.facturas.forEach(factura => {
              console.log('Factura:', factura); // Log para ver la estructura completa de cada factura
              console.log('PDF URL:', factura.pdfUrl); // Verifica la URL del PDF
            }); // Verifica en la consola si se están recibiendo las facturas
          } else {
            this.modalMessage = "No hay facturas disponibles.";
            this.openModal(this.modalRespuesta);
          }
        } else {
          this.modalMessage = res.message;
          this.openModal(this.modalRespuesta);
        }
      });
  }

  logout() {
    this.authSrv.logout();
  }
  openModal(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-socios-form",
    });
  }

  getMonth(fecha) {
    let formatofecha = fecha.split("/");
    let fechafinal =
      formatofecha[1] + "/" + formatofecha[0] + "/" + formatofecha[2];
    return new Intl.DateTimeFormat("es-ES", { month: "long" }).format(
      new Date(fechafinal)
    );
  }
  getYear(fecha) {
    let formatofecha = fecha.split("/");

    return formatofecha[2];
  }

  descargarFacturaPDF(letra: string, numero: string) {
    console.log('Iniciando descarga del PDF'); // Log para indicar que se inicia la descarga
    console.log('Datos de la factura:', { letra, numero, dominio: this.datosEmpresa.empresa_dominio }); // Log de los datos que se enviarán

    const body = {
      letra: letra,
      numero: numero,
      dominio: this.datosEmpresa.empresa_dominio // Usar el dominio de la empresa
    };

    this.http
      .post(`${API_BASE_URL_V}/empresa/factura/pdf/`, body, { responseType: 'blob' })
      .subscribe((blob) => {
        console.log('Respuesta de la API recibida para la descarga'); // Log para indicar que se recibió la respuesta
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `factura_${numero}.pdf`; // Nombre del archivo
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        console.log('PDF descargado exitosamente:', `factura_${numero}.pdf`); // Log para indicar que la descarga fue exitosa
      }, (error) => {
        console.error('Error al descargar el PDF:', error); // Log de error al descargar
      });
  }


}
