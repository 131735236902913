import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { API_BASE_URL_JSON } from "src/app/data-wp.service";
import { emailData } from "src/app/shared/emailData";
import { SocioData } from "src/app/shared/socio-data.interface";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: "app-cambio-de-domicilio",
  templateUrl: "./cambio-de-domicilio.component.html",
  styleUrls: ["../../socios-gestion-online.component.css"],
})
export class CambioDeDomicilioComponent implements OnInit {
  form: FormGroup;

  @Output() closeModal = new EventEmitter();
  @ViewChild("modalRespuesta", { static: false }) modalRespuesta: ElementRef;

  constructor(
    public formBuilder: FormBuilder,
    private http: HttpClient,
    private modalService: NgbModal,
    private spinner: NgxUiLoaderService
  ) { }

  get calle() {
    return this.contactForm.get("calle");
  }
  get altura() {
    return this.contactForm.get("altura");
  }
  get piso() {
    return this.contactForm.get("piso");
  }
  get departamento() {
    return this.contactForm.get("departamento");
  }
  get entreCalles() {
    return this.contactForm.get("entreCalles");
  }
  get localidad() {
    return this.contactForm.get("localidad");
  }
  get cp() {
    return this.contactForm.get("cp");
  }
  get telefono() {
    return this.contactForm.get("cp");
  }

  contactForm = this.formBuilder.group({
    calle: ["", [Validators.required]],
    altura: ["", [Validators.required]],
    piso: ["", [Validators.required]],
    departamento: ["", [Validators.required]],
    entreCalles: ["", [Validators.required]],
    localidad: ["", [Validators.required]],
    cp: ["", [Validators.required]],
    telefono: ["", [Validators.required, Validators.pattern("^[0-9]+$")]],
  });

  datosSocio: SocioData;
  modalMessage: string;

  ngOnInit() {
    this.datosSocio = JSON.parse(sessionStorage.getItem("data"));
  }

  submitForm() {
    this.spinner.start();
    let formData = new FormData();
    let form = this.contactForm.value;
    formData.append("numero_afiliado", this.datosSocio.socio_numero);
    formData.append("nombre_afiliado", this.datosSocio.socio_nombre);
    formData.append("apellido_afiliado", this.datosSocio.socio_apellido);
    formData.append("email_afiliado", this.datosSocio.socio_email);
    formData.append("dominio_afiliado", this.datosSocio.socio_dominio);
    formData.append("calle", form.calle);
    formData.append("altura", form.altura);
    formData.append("piso", form.piso);
    formData.append("departamento", form.departamento);
    formData.append("entre_calles", form.entreCalles);
    formData.append("localidad", form.localidad);
    formData.append("codigo_postal", form.cp);
    formData.append("telefono", form.telefono);
    this.datosSocio.socio_dominio.length > 0
      ? formData.append(
        "email_destino",
        emailData[this.datosSocio.socio_dominio]
      )
      : formData.append("email_destino", "cas@vittal.com.ar");
    this.http
      .post<any>(
        API_BASE_URL_JSON + "/contact-form-7/v1/contact-forms/3713/feedback",
        formData
      )
      .subscribe((res) => {
        this.spinner.stop();
        this.closeModal.emit();
        this.modalMessage = res.message;
        this.openModal(this.modalRespuesta);
      });
  }

  openModal(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-socios-form",
    });
  }
}
